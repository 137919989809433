import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Typography } from '@material-ui/core';

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import ThemeContext from '../../contexts/theme-context';
import { useMediaQuery } from '@material-ui/core';

import { Waypoint } from 'react-waypoint';

import businessIndustrialIcon from '../../images/svg/industries/business-industrial-industry-icon.svg';
import cannabisIcon from '../../images/svg/industries/cannabis-industry-icon.svg';
import educationIcon from '../../images/svg/industries/education-industry-icon.svg';
import marketingIcon from '../../images/svg/industries/marketing-industry-icon.svg';
import petsIcon from '../../images/svg/industries/pets-industry-icon.svg';
import realEstateIcon from '../../images/svg/industries/real-estate-industry-icon.svg';
import retailIcon from '../../images/svg/industries/retail-industry-icon.svg';
import sportsIcon from '../../images/svg/industries/sports-industry-icon.svg';
import technologyIcon from '../../images/svg/industries/technology-industry-icon.svg';
import telecommunicationsIcon from '../../images/svg/industries/telecommunications-industry-icon.svg';
import transportationIcon from '../../images/svg/industries/transportation-industry-icon.svg';

const useCarouselStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    overflow: 'hidden',
    '&, & *': {
      outline: 'none',
    },
  },
  sliderContainer: {
    position: 'relative',
  },
  sliderBottom: {
    bottom: 0,
    position: 'absolute',
  },
  slider: (appBarVisible) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    margin: '0 -20px',
    paddingTop: 40,
    position: 'absolute',
    top: 0,
    '&--fixed': {
      paddingTop: 40 + (appBarVisible ? 150 : 0),
      position: 'fixed',
      transition: `padding-top ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut}`,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 40 + (appBarVisible ? 96 : 0),
      },
    },
    '&--end': {
      bottom: 0,
      position: 'absolute',
      top: 'auto',
    },
  }),
  industry: {
    flexShrink: 0,
    padding: '0 20px',
    '&__images': {
      position: 'relative',
      width: '100%',
    },
    '&__image': {
      display: 'block',
      opacity: 0.5,
      position: 'relative',
      transition: 'opacity 0.4s ease-in',
      zIndex: 1,
      '& picture, & img': {
        height: 'auto',
        width: '100%',
      },
      '&:hover': {
        opacity: 1,
        cursor: 'pointer',
      },
    },
    '&__icon': {
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      bottom: 0,
      display: 'flex',
      height: 125,
      justifyContent: 'center',
      position: 'absolute',
      transform: 'translateZ(0)',
      right: 0,
      width: 125,
      zIndex: 2,
      '& img': {
        height: 54,
        width: 'auto',
      },
    },
    '&__heading': {
      padding: '30px 15px 0',
    },
    [theme.breakpoints.down('md')]: {
      width: 320,
    },
    [theme.breakpoints.up('lg')]: {
      width: 440,
    },
  },
}));

const industries = [
  {
    imageSlug: 'business-industrial',
    icon: businessIndustrialIcon,
    title: 'Business & Industrial Markets',
  },
  {
    imageSlug: 'cannabis',
    icon: cannabisIcon,
    title: 'Cannabis',
  },
  {
    imageSlug: 'education',
    icon: educationIcon,
    title: 'Education & Learning',
  },
  {
    imageSlug: 'marketing',
    icon: marketingIcon,
    title: 'Marketing & Agencies',
  },
  {
    imageSlug: 'pets',
    icon: petsIcon,
    title: 'Pets & Animals',
  },
  {
    imageSlug: 'real-estate',
    icon: realEstateIcon,
    title: 'Real Estate',
  },
  {
    imageSlug: 'retail',
    icon: retailIcon,
    title: 'Retail',
  },
  {
    imageSlug: 'sports',
    icon: sportsIcon,
    title: 'Sports',
  },
  {
    imageSlug: 'technology',
    icon: technologyIcon,
    title: 'Technology & Innovation',
  },
  {
    imageSlug: 'telecommunications',
    icon: telecommunicationsIcon,
    title: 'Telecommunications',
  },
  {
    imageSlug: 'transportation',
    icon: transportationIcon,
    title: 'Transportation & Fuel',
  },
];

const Image = ({ fileName, className = '', alt = '' }) => {
  const imageData = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { regex: "/png|jpg/" } }) {
          nodes {
            base
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    `
  );

  const imageMatch = imageData.allFile.nodes.find(
    (n: any) => n.base === fileName
  ).childImageSharp.gatsbyImageData;

  const image = getImage(imageMatch);

  return image ? (
    <GatsbyImage
      className={className}
      image={image}
      alt={alt}
      objectFit="cover"
      objectPosition="50% 50%"
    />
  ) : null;
};

const IndustryCarousel: React.FC = () => {
  const { appBarVisible } = useContext(ThemeContext);

  const sliderContainerRef = useRef<HTMLDivElement>(null);
  const sliderBottomRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const [lastScrollY, setLastScrollY] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const carouselStyles = useCarouselStyles(appBarVisible);

  const onEnterTop = ({ currentPosition }) => {
    setIsFixed(currentPosition !== 'inside');
  };

  const onLeaveTop = ({ currentPosition }) => {
    setIsFixed(currentPosition === 'above');
  };

  const onEnterBottom = ({ currentPosition, previousPosition }) => {
    if (currentPosition === 'inside' && previousPosition === 'above') {
      setIsFixed(true);
      setIsEnd(false);
    }
  };

  const onLeaveBottom = ({ currentPosition, previousPosition }) => {
    setIsEnd(currentPosition === 'above' && previousPosition === 'inside');
  };

  const onResize = () => {
    if (
      sliderContainerRef.current &&
      sliderBottomRef.current &&
      sliderRef.current
    ) {
      sliderContainerRef.current.style.height = `${sliderRef.current.offsetWidth}px`;
      sliderBottomRef.current.style.height = `${sliderRef.current.offsetHeight}px`;
    }
  };

  const onScroll = () => {
    if (sliderContainerRef.current && sliderRef.current) {
      const newScrollY = window.scrollY;

      if (isFixed || isEnd) {
        const scrolledBy = (newScrollY - lastScrollY) * -1;

        const currentPositionMatch = sliderRef.current.style.transform.match(
          /-?\d+/
        );

        let currentPosition = currentPositionMatch
          ? parseInt(currentPositionMatch[0])
          : 0;

        currentPosition += scrolledBy;

        if (currentPosition > 0) currentPosition = 0;

        sliderRef.current.style.transform = `translateX(${currentPosition}px)`;
      } else {
        sliderRef.current.style.transform = 'translateX(0)';
      }

      setLastScrollY(newScrollY);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    onScroll();

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onScroll);
    };
  }, [lastScrollY, isFixed, isEnd]);

  let sliderClasses = carouselStyles.slider;

  if (isFixed) sliderClasses += ` ${carouselStyles.slider}--fixed`;

  if (isEnd) sliderClasses += ` ${carouselStyles.slider}--end`;

  const theme = useTheme();
  const isMobileAppBar = useMediaQuery(theme.breakpoints.down('sm'));

  const waypointOffset = appBarVisible ? (isMobileAppBar ? 96 : 150) : 0;

  return (
    <div className={carouselStyles.root}>
      <Container>
        <Waypoint
          onEnter={onEnterTop}
          onLeave={onLeaveTop}
          topOffset={waypointOffset}
        />
        <div
          className={carouselStyles.sliderContainer}
          ref={sliderContainerRef}
        >
          <div className={sliderClasses} ref={sliderRef}>
            {industries.map((industry, index) => (
              <div className={`${carouselStyles.industry}`} key={index}>
                <div className={`${carouselStyles.industry}__images`}>
                  <Image
                    className={`${carouselStyles.industry}__image`}
                    fileName={`${industry.imageSlug}-industry.jpg`}
                    alt={`${industry.title}`}
                  />
                  <div className={`${carouselStyles.industry}__icon`}>
                    <img
                      src={industry.icon}
                      alt={`${industry.title} icon`}
                      width="54"
                      height="54"
                    />
                  </div>
                </div>
                <Typography
                  className={`${carouselStyles.industry}__heading`}
                  variant="h6"
                  component="h3"
                  color="primary"
                >
                  {industry.title}
                </Typography>
              </div>
            ))}
          </div>
          <div className={carouselStyles.sliderBottom} ref={sliderBottomRef}>
            <Waypoint
              onEnter={onEnterBottom}
              onLeave={onLeaveBottom}
              bottomOffset={waypointOffset}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default IndustryCarousel;
