import React from 'react';
import Layout from '../components/global/layout';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import Button from '../components/mui/button';
import PageBanner from '../components/layouts/page-banner';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';
import IndustryCarousel from '../components/layouts/industry-carousel';
import CallToAction from '../components/layouts/call-to-action';
import SEO from '../components/seo';
import theme from '../lib/theme';
import { makeStyles } from '@material-ui/core/styles';
import squareMLogo from '../images/svg/about-m-logo.svg';

const useAboutStyles = makeStyles((theme) => ({
  foundation: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      '& [class*="MuiGrid-container"]': {
        justifyContent: 'center',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& [class*="MuiGrid-container"]': {
        justifyContent: 'flex-start',
      },
      '&__heading': {
        maxWidth: 650,
        paddingRight: `${theme.spacing(8)}px !important`,
      },
      '&__description': {
        maxWidth: 500,
      },
    },
  },
  trust: {
    '&__logo': {
      display: 'block',
      height: 'auto',
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      '&__logo': {
        maxWidth: 200,
      },
    },
    [theme.breakpoints.between('sm', 'md')]: {
      '&__logo': {
        maxWidth: 300,
      },
    },
    [theme.breakpoints.up('lg')]: {
      '&__logo': {
        margin: 'auto',
        maxWidth: 400,
      },
    },
  },
}));

const AboutPage: React.FC = () => {
  const aboutStyles = useAboutStyles();

  return (
    <Layout>
      <SEO
        title="About Us"
        description="An experienced team offering integrated web operations services for small, medium, large, and enterprise clients."
      />
      <PageBanner
        maxWidth={900}
        heading="Rely on Proven Website Expertise"
        imageSrc="about-banner.jpg"
      />
      <Box bgcolor="primary.main">
        <VerticalPadding>
          <Container>
            <Grid container justify="center">
              <Grid
                item
                xs={12}
                md={10}
                lg={9}
                xl={8}
                style={{ textAlign: 'center' }}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  style={{
                    color: 'white',
                    fontFamily: 'Visby CF',
                    textTransform: 'unset',
                  }}
                >
                  Marvel <span style={{ fontSize: '0.8em' }}>&amp;</span> Snap
                  provides small, medium, large, and enterprise clients across
                  North America with development, hosting, and maintenance web
                  solutions.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </Box>
      <VerticalPadding className={aboutStyles.foundation}>
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid
              item
              className={`${aboutStyles.foundation}__heading`}
              xs={12}
              sm={10}
              lg
            >
              <Typography variant="h2" color="textSecondary">
                Our integrated web operations services
              </Typography>
            </Grid>
            <Grid
              item
              className={`${aboutStyles.foundation}__description`}
              xs={12}
              sm={10}
              lg
            >
              <Typography
                variant="subtitle1"
                style={{ color: theme.palette.neutral.main }}
              >
                are the foundation of digital marketing initiatives for
                companies in a variety of industries, including:
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <IndustryCarousel />
      </VerticalPadding>
      <LightMode>
        <VerticalPadding>
          <Container maxWidth={false}>
            <Grid
              container
              spacing={4}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={12} lg={3}>
                <img
                  className={`${aboutStyles.trust}__logo`}
                  src={squareMLogo}
                  alt=""
                  width="324"
                  height="324"
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography
                  variant="h2"
                  color="textSecondary"
                  gutterBottom={true}
                >
                  Trust an Experienced Partner
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className="lead"
                  style={{ fontSize: 18 }}
                  gutterBottom={true}
                >
                  Our extensive experience with all aspects of web operations
                  has provided us with a complete understanding of the
                  challenges associated with development, hosting, and
                  management of complex digital assets in today&apos;s business
                  environment &ndash; not just from a technical complexity and
                  project management perspective, but also from a cost
                  perspective.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className="lead"
                  style={{ fontSize: 18 }}
                >
                  With Marvel &amp; Snap, you get enterprise-grade development,
                  hosting, and ongoing management services that enable more
                  efficient, ongoing collaboration between marketing and web
                  operations teams, and streamline efforts to make the web a
                  more integrated tool of a digital marketing strategy that
                  drives business growth.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </LightMode>
      <CallToAction imageSrc="call-to-action-laptop-couch.jpg">
        <Typography
          variant="h2"
          color="textSecondary"
          gutterBottom={true}
          style={{ maxWidth: '9em' }}
        >
          Get worry-free web operations
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: 24, marginTop: 40, marginBottom: 50 }}
        >
          Develop, launch, manage, and iterate digital marketing assets faster.
          And bring advanced digital experiences to your audience more quickly
          and efficiently.
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          to="/contact/"
        >
          Let&apos;s talk
        </Button>
      </CallToAction>
    </Layout>
  );
};

export default AboutPage;
